import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast, Toaster } from "react-hot-toast";
import NavBar from "../components/NavbarTwo";
import FooterFour from "../components/FooterFour";
import { Link } from "react-router-dom";

const ApplyForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_i8oi9ck",
        "template_rmc7phb",
        form.current,
        "IHdpjRXzLYuE_6zdx"
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            toast.success("Message Sent Sucessfully!");
          }
          console.log(result.text);
        },
        (error) => {
          if (error.text !== "OK") {
            toast.success("Message Not Sent!");
          }
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      <NavBar />
      <div className="contact-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="contact-page-inner bg-gray">
            <div className="section-title mb-4 pb-2">
              <h2 className="title">Lets Kickstart your Idea </h2>
              <p className="content mb-0">
                We are committed to support and encourage outstanding technology
                entrepreneurs to innovate, grow and bring game changing ideas to
                the global marketplace.
              </p>
            </div>

            <form ref={form} onSubmit={sendEmail}>
              <div className="row">
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="venture"
                      name="venture_name"
                      type="text"
                      placeholder="Project / Name of company."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="email"
                      name="company_email"
                      type="email"
                      placeholder="Enter Your Email."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="location"
                      name="location"
                      type="text"
                      placeholder="Enter Your Location."
                      required
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="raise"
                      name="current_raise"
                      type="text"
                      placeholder="Current Raise."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <textarea
                      id="fund"
                      name="fund_raise"
                      cols="1"
                      rows="5"
                      placeholder="Fundraising Details."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <textarea
                      id="previous_raise"
                      name="previous_raise"
                      cols="1"
                      rows="5"
                      placeholder="Previous Fundraising Details."
                      required
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="project"
                      name="project_link"
                      type="text"
                      placeholder="Project Link / Demo."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="source"
                      name="source_raise"
                      type="text"
                      placeholder="How did you hear about us."
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="single-input-inner">
                    <input
                      id="apply"
                      name="apply_visa"
                      type="text"
                      placeholder="Intending to apply for a start-up visa?."
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 form-check">
                  <input className="form-check-input" type="checkbox" value="" id="optin" />
                  <label className="form-check-label" for="optin" >I agree to<Link to="/terms">Terms &amp; Condition</Link> provided by Evolvelab Solutions Inc. By providing my phone number. I agree to recieve text messages from the business</label>
                </div>
              <div className="col-md-12 text-center">
                <button
                  type="submit"
                  id="submitBtn"
                  className="btn btn-main btn-base border-radius-5 mr-20"
                >
                  Submit
                </button>
              </div>
            </form>
            <Toaster position="bottom-center" reverseOrder={false} />
          </div>
        </div>
      </div>
      <FooterFour />
    </>
  );
};

export default ApplyForm;
