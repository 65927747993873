import React from "react";
import NavBar from "../components/NavbarTwo";
import FooterFour from "../components/FooterFour";

const Innovation = () => {
  return (
    <>
      <NavBar />
      <div className="contact-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="contact-page-inner bg-gray">
            <div className="section-title mb-4 pb-2">
              <h2 className="title">Innovation Page </h2>
              <p className="content mb-0">Placeholder for Innovation page</p>
            </div>
          </div>
        </div>
      </div>
      <FooterFour />
    </>
  );
};

export default Innovation;
