import React from "react";
import { Link } from "react-router-dom";

const TeamAreaGroup = () => {
  return (
    <>
      {/* ================= TeamAreaGroup Start =================*/}
      <div className="team-area bg-relative pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img
                    src="assets/img/gallery/Freemedica.png"
                    alt="img"
                    style={{
                      height: "90px",
                      paddingTop: "12px",
                    }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link to="#/">FREmedica</Link>
                  </h5>
                  {/* <p>Merketing Department</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img
                    src="assets/img/gallery/Artron.png"
                    alt="img"
                    style={{
                      height: "90px",
                      paddingTop: "12px",
                    }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link to="/#">Artron Bioresearch</Link>
                  </h5>
                  {/* <p>It Department</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img
                    src="assets/img/gallery/digni.png"
                    alt="img"
                    style={{
                      height: "90px",
                      paddingTop: "12px",
                    }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link to="/#">Digni</Link>
                  </h5>
                  {/* <p>Software Department</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img
                    style={{
                      height: "90px",
                      paddingTop: "12px",
                    }}
                    src="assets/img/gallery/influentia.jpeg"
                    alt="img"
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link to="/#">Influent Group</Link>
                  </h5>
                  {/* <p>Merketing Department</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img
                    src="assets/img/gallery/dtech.png"
                    alt="img"
                    style={{
                      height: "90px",
                      paddingTop: "12px",
                    }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link to="/#">D1 Tech Global</Link>
                  </h5>
                  {/* <p>It Department</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img
                    src="assets/img/gallery/bit.jpeg"
                    alt="img"
                    style={{
                      height: "90px",
                      paddingTop: "12px",
                    }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link to="/#">BitHappier</Link>
                  </h5>
                  {/* <p>Software Department</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img
                    src="assets/img/gallery/soteria.png"
                    alt="img"
                    style={{
                      height: "90px",
                      paddingTop: "12px",
                    }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link to="/#">Soteria120</Link>
                  </h5>
                  {/* <p>Software Department</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img
                    src="assets/img/gallery/jonny.avif"
                    alt="img"
                    style={{
                      height: "90px",
                      paddingTop: "12px",
                    }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link to="/#">Jonny Hetherington Essentials</Link>
                  </h5>
                  {/* <p>Software Department</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img
                    src="assets/img/gallery/immigrant.png"
                    alt="img"
                    style={{
                      height: "90px",
                      paddingTop: "12px",
                    }}
                  />
                </div>
                <div className="details">
                  <h5>
                    <Link to="/#">Immigration Network</Link>
                  </h5>
                  {/* <p>Software Department</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================= TeamAreaGroup End =================*/}
    </>
  );
};

export default TeamAreaGroup;
