/* eslint-disable */
import React from "react";
import {
  FaChevronRight,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaRegCalendarAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterFour = () => {
  return (
    <>
      {/* ================== Footer Four Start ==================*/}
      <footer
        className="footer-area bg-cover mt-0 pd-top-120"
        style={{ backgroundImage: 'url("assets/img/bg/14.png")' }}
      >
        
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="widget widget-recent-post">
                <h4 className="widget-title">Contact us</h4>
                <div className="widget widget_contact">
                  <ul className="details text-white">
                    <li>
                      <FaMapMarkerAlt className="sky" />
                      151 W. Hastings St. Vancouver, BC V6B 1H4
                    </li>
                    <li className="mt-3">
                      <FaPhoneAlt className="sky" /> +1 6047670944
                    </li>
                    <li className="mt-2">
                      <FaEnvelope className="sky" /> info@evolelab.com
                    </li>
                  </ul>
                  <ul className="social-media mt-4">
                    <li>
                      <a href="/#" className="sky">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="/#" className="sky">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="/#" className="sky">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="/#" className="sky">
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title"></h4>
                <ul>
                  <li className="sky">
                    <Link to="/">
                      <FaChevronRight /> Home
                    </Link>
                  </li>
                  <li className="sky">
                    <Link to="/portfolio">
                      <FaChevronRight /> Portfolio
                    </Link>
                  </li>
                  <li className="sky">
                    <Link to="/innovation">
                      <FaChevronRight /> Innovation
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 ps-xl-5">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title"></h4>
                <ul>
                  <li className="sky">
                    <Link to="/about">
                      <FaChevronRight /> About Us
                    </Link>
                  </li>
                  <li className="sky">
                    <Link to="/#">
                      <FaChevronRight /> Careers
                    </Link>
                  </li>
                  <li className="sky">
                    <Link to="/apply">
                      <FaChevronRight /> Apply
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              {/* <div className="widget widget-recent-post">
                <h4 className="widget-title">Recent Blog</h4>
                <ul>
                  <li className="sky">
                    <div className="media">
                      <div className="media-body align-self-center">
                        <div className="post-info  mb-2">
                          <FaRegCalendarAlt className="sky" />
                          <span>january 11, 2023</span>
                        </div>
                        <h6 className="title sky mb-0">
                          <Link to="/blog-details">
                            Social Media For Promote Business.
                          </Link>
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li className="sky">
                    <div className="media">
                      <div className="media-body align-self-center">
                        <div className="post-info mb-2">
                          <FaRegCalendarAlt className="sky" />
                          <span>january 11, 2023</span>
                        </div>
                        <h6 className="title sky mb-0">
                          <Link to="/blog-details">
                            Marketing For Base marketing watch
                          </Link>
                        </h6>
                      </div>
                    </div>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p>© EvolveLab 2023 | All Rights Reserved</p>
              </div>
              <div className="col-md-6 text-lg-end">
              <Link to="/terms">
                Terms &amp; Condition
                    </Link>
                    <Link to="/privacy">Privacy Policy</Link>
                    <Link to="/contact">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer Two  end ==================*/}
    </>
  );
};

export default FooterFour;
