import React from "react";
import { Helmet } from "react-helmet-async";
import AboutAreaFive from "../components/AboutAreaFive";
import AboutAreaSix from "../components/AboutAreaSix";
import BannerFive from "../components/BannerFive";
import FooterFour from "../components/FooterFour";
import NavbarFour from "../components/NavbarFour";
import ServiceAreaFive from "../components/ServiceAreaFive";

const HomeFive = () => {
  return (
    <>
      <Helmet>
        <title>Evolve Lab</title>
        <meta
          name="description"
          content="Canada Pre-seed Venture Startup finds great ideas and founders in Canada and beyond that are ready to build massively scalable businesses and work with them to grow their brand
 ."
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <NavbarFour />
      <BannerFive />
      <ServiceAreaFive />
      <AboutAreaFive />
      <AboutAreaSix />

      <FooterFour />
    </>
  );
};

export default HomeFive;
