import React from "react";
import ContactMain from "../components/ContactMain";
import FooterOne from "../components/FooterFour";
import NavBar from "../components/NavbarTwo";

const Contact = () => {
  return (
    <>
      <NavBar />
      <ContactMain />
      <FooterOne />
    </>
  );
};

export default Contact;
