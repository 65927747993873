import React from "react";
import FooterOne from "../components/FooterFour";
import NavBar from "../components/NavbarTwo";
import TeamAreaGroup from "../components/TeamAreaGroup";

const Portfolio = () => {
  return (
    <>
      <NavBar />
      <TeamAreaGroup />
      <FooterOne />
    </>
  );
};

export default Portfolio;
