import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import CaseStudyDetails from "./pages/CaseStudyDetails";
import Contact from "./pages/Contact";
import HomeFour from "./pages/HomeFour";
import HomeOne from "./pages/HomeOne";
import HomeThree from "./pages/HomeThree";
import HomeTwo from "./pages/HomeTwo";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import HomeFive from "./pages/HomeFive";
import ApplyForm from "./pages/ApplyForm";
import Portfolio from "./pages/Portfolio";
import Career from "./pages/Career";
import TeamDetailsSingle from "./pages/TeamDetailsSingle";
import Innovation from "./pages/Innovation";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomeFive />} />
        <Route exact path="/index-2" element={<HomeTwo />} />
        <Route exact path="/index-3" element={<HomeThree />} />
        <Route exact path="/index-4" element={<HomeFour />} />
        <Route exact path="/index-5" element={<HomeOne />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/blog-details" element={<BlogDetails />} />
        <Route
          exact
          path="/case-study-details"
          element={<CaseStudyDetails />}
        />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/service" element={<Service />} />
        <Route exact path="/service-details" element={<ServiceDetails />} />
        <Route exact path="/team" element={<Team />} />
        <Route exact path="/team-details" element={<TeamDetails />} />
        <Route exact path="/apply" element={<ApplyForm />} />
        <Route exact path="portfolio" element={<Portfolio />} />
        <Route exact path="/careers" element={<Career />} />
        <Route exact path="/team/:id" element={<TeamDetailsSingle />} />
        <Route exact path="/innovation" element={<Innovation />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/privacy" element={<Privacy />} />
      </Routes>
      <ScrollToTop smooth color="#246BFD" />
    </BrowserRouter>
  );
}

export default App;
