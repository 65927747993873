export const userData = [
  {
    id: "1",
    name: "Mike Qureshi",
    position: "CEO, Founder",
    description: "",
    description_one: "",
    description_two: "",
    img: "/assets/img/team/mike.jpeg",
  },
  {
    id: "2",
    name: "Praveen K. Varshney",
    position: "Director, Co-Founder",
    description:
      "Praveen has been a principal of Varshney Capital Corp., a Vancouver based family office, since 1991.  He's also a Founding Director of Pyfera Growth Capital & a Founding Director of Humanitas Smart Planet Fund, both with a social impact focus.  Mr. Varshney obtained his UBC Sauder BComm in 1987 and is a FCPA, FCA",
    description_one:
      "He has been a Co-Founder, Director or Officer of many publicly traded companies over the years including one of Canada's largest fintech companies, Mogo Inc. (NASDAQ: MOGO & TSX: MOGO), NEXE Innovations & Little Kitchen Academy.  He’s also on four charity boards such as The Vancouver Foundation & Foundations for Social Change.",
    description_two:
      "Praveen is also a recipient of Business in Vancouver's 40 Under 40 Awards, the 2020 Greater Vancouver Board of Trade Wendy McDonald Diversity Awards Outstanding Mentor and 2021 inaugural Business in Vancouver Top 500 Most Influential Business Leaders in BC (Banking & Finance - Private Equity, Venture Capital & Hedge Funds category)",
    img: "/assets/img/team/praveen.png",
  },
  {
    id: "3",
    name: "Adnan Sarwar",
    position: "Mentor to CEO | Marketing Advisor ",
    description:
      "Adnan Sarwar, worked as an Investment Banker for Merrill Lynch in London, U.K., is now working as a Faculty at University Canada West and sits on the Advisory Board of University of California, Riverside campus. He wishes to serve EvolveLab as the Chief Marketing Officer (CMO) with a focus on Business Development, Marketing, Leadership, Public Speaking, Corporate Client Networking and Building a solid brand and recognition in the global market synonymous with Innovation in Technology using Artificial Intelligence (AI) and Machine Learning (ML), in Canada, USA and globally.",
    description_one: "",
    description_two: "",
    img: "/assets/img/team/adnan.jpg",
  },
  {
    id: "4",
    name: "Akshay Gupta",
    position: "Advisor",
    description: "",
    description_one: "",
    description_two: "",
    img: "/assets/img/team/Akshay-gupta.jpeg",
  },
  {
    id: "5",
    name: "Aakash Anantharaman",
    position: "Grant Architect",
    description: "",
    description_one: "",
    description_two: "",
    img: "/assets/img/team/akash.png",
  },
  {
    id: "6",
    name: "Manuj Aggarwal",
    position: "AI Advisor",
    description: "Manuj Aggarwal, is the founder and Chief Innovation Officer TetraNoodle Technologies, an elite AI consulting company. With a remarkable track record of driving transformative change, Manuj, through TetraNoodle, has touched at least 10 million lives and generated over $500M in value through his expertise in technology and AI.  ",
    description_one: " He has shared his insights at the United Nations alongside distinguished figures like the Secretary-General and Nobel Peace Prize winners, while President Obama and Bill Gates have recognized his work. With four AI patents to his name",
    description_two: "Manuj is the perfect mentor to guide you through the transformative potential of AI and its benefits for businesses. Join us and discover the limitless possibilities of AI under the guidance of this visionary industry influencer.",
    img: "/assets/img/team/Manuj.jpg",
  },
];
