import React from "react";
import AboutAreaOne from "../components/AboutAreaOne";
import FooterOne from "../components/FooterFour";
import NavBarTwo from "../components/NavbarTwo";
import TeamAreaOne from "../components/TeamAreaOne";

const About = () => {
  return (
    <>
      <NavBarTwo />
      <AboutAreaOne />
      <TeamAreaOne />
      <FooterOne />
    </>
  );
};

export default About;
