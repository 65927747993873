import React from "react";
import NavBar from "../components/NavbarTwo";
import FooterFour from "../components/FooterFour";

const Terms = () => {
  return (
    <>
      <NavBar />
      <div className="contact-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="contact-page-inner bg-gray">
            <div className="section-title mb-4 pb-2">
              <h2 className="title">Terms and Condition </h2>
              <p className="content mb-0"><h5>  1. Introduction</h5>

<strong>Overview:</strong> Briefly explain the purpose of the terms and conditions. <br/>
<strong>Acceptance of Terms:</strong> Specify that use of the website constitutes acceptance of these terms.</p>
<p className="content mb-0">  
  <h5>2. Services Offered</h5>

 <strong>Description:</strong>  Define the tech and financial services offered on the website.<br/>
 <strong>Limitations:</strong>  Outline any restrictions or limitations on services.<br/>
</p>
<p className="content mb-0"> 
<h5>3. User Responsibilities</h5>

<strong>Eligibility:</strong> Specify who can use the services (e.g., age requirements).<br/>
<strong>Account Registration:</strong> Detail the process for creating and maintaining user accounts.<br/>
<strong>User Conduct:</strong> Define acceptable behavior on the website (e.g., no spamming, no unauthorized use).
</p>
<p className="content mb-0"> 
<h5>4. Payments and Billing</h5>

<strong>Pricing:</strong> Detail the costs associated with services.<br/>
<strong>Payment Terms:</strong> Specify accepted payment methods and billing cycles.<br/>
<strong>Refunds:</strong> Explain the refund policy if applicable.
</p>
<p className="content mb-0"> 
<h5>5. Intellectual Property</h5>

<strong>Ownership:</strong> Specify ownership rights of content and intellectual property on the website.<br/>
<strong>Use of Materials:</strong> Define how users may use or interact with intellectual property.
</p>
<p className="content mb-0"> 
<h5>6. Data Privacy and Security</h5>

<strong>Privacy Policy:</strong> Link to a comprehensive privacy policy explaining data collection, use, and protection.<br/>
<strong>Data Security:</strong> Explain measures taken to protect user data.
</p>
<p className="content mb-0"> 
<h5>7. Dispute Resolution</h5>

<strong>Governing Law:</strong> Specify which jurisdiction’s laws apply.<br/>
<strong>Dispute Resolution: </strong>Outline how disputes will be handled (e.g., arbitration, mediation).
</p>
<p className="content mb-0">
<h5>8. Limitation of Liability</h5>

<strong>Disclaimer:</strong> Clarify limitations of liability for damages related to service use.<br/>
<strong>Indemnification:</strong> Define user responsibilities in case of legal claims.
 </p>
<p className="content mb-0"> 
<h5>9. Termination</h5>

<strong>Termination Clause:</strong> Explain conditions under which accounts or services may be terminated.
</p>
<p className="content mb-0"> 
<h5>10. Changes to Terms</h5>

<strong>Amendment:</strong> Reserve the right to update or modify terms and conditions.<br/>
<strong>Notification:</strong> Describe how users will be informed of changes.
</p>
<p className="content mb-0">
<h5>11. Miscellaneous</h5>

<strong>Severability:</strong> Clarify that if any portion of the terms is deemed invalid, the rest remains in effect.<br/>
<strong>Entire Agreement:</strong> State that these terms constitute the entire agreement between the user and the company. </p>
            </div>
          </div>
        </div>
      </div>
      <FooterFour />
    </>
  );
};

export default Terms;
