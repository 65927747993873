import React from "react";
import { Link } from "react-router-dom";
import { userData } from "../scripts/data";

const TeamAreaOne = () => {
  console.log(userData, "ji");
  return (
    <>
      {/*====================== team area start ======================*/}
      <div className="team-area bg-relative pd-top-90 pd-bottom-90">
        <div className="container">
          <div className="section-title text-center">
            <h6 className="sub-title">MEET OUR EXPERTS</h6>
            <h2 className="title">
              Your Partners In <span style={{ color: "#4faf9b" }}>Success</span>{" "}
            </h2>
          </div>
          <div className="row">
            {userData.map((user) => {
              return (
                <div key={user.id} className="col-lg-4 col-md-6">
                  <div className="single-team-inner text-center">
                    <div className="thumb">
                      <Link to={`/team/${user.id}`}>
                        {" "}
                        <img src={user.img} alt="img" style={{minWidth:"100%"}}/>
                      </Link>
                    </div>
                    <div className="details">
                      <h5>
                        <Link to="/#">{user.name}</Link>
                      </h5>
                      <p>{user.position}</p>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img src="assets/img/team/mike.jpeg" alt="img" />
                  <ul className="team-social-inner">
                    <li>
                      <a href="/#">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <Link to="/#">Mike Qureshi</Link>
                  </h5>
                  <p>CEO, Co-Founder</p>
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img src="assets/img/team/praveen.png" alt="img" />
                  <ul className="team-social-inner">
                    <li>
                      <a href="/#">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <Link to="/#">Praveen Varshney </Link>
                  </h5>
                  <p>Director, Co-Founder</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img src="assets/img/team/adnan.jpg" alt="img" />
                  <ul className="team-social-inner">
                    <li>
                      <a href="/#">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <Link to="/#">Adnan H. Sarwar</Link>
                  </h5>
                  <p>Chief Marketing Officer (CMO), Co-founder</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img
                    src="assets/img/team/Akshay-gupta.jpeg"
                    style={{ width: "100%" }}
                    alt="Akshay Gupta"
                  />
                  <ul className="team-social-inner">
                    <li>
                      <a href="/#">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <Link to="/#">Akshay Gupta</Link>
                  </h5>
                  <p>Advisor</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img src="assets/img/team/2.png" alt="img" />
                  <ul className="team-social-inner">
                    <li>
                      <a href="/#">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <Link to="/#"> Test </Link>
                  </h5>
                  <p>CXO</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner text-center">
                <div className="thumb">
                  <img src="assets/img/team/2.png" alt="img" />
                  <ul className="team-social-inner">
                    <li>
                      <a href="/#">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="details">
                  <h5>
                    <Link to="/#">Test</Link>
                  </h5>
                  <p>CXO</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* ====================== team area end ======================*/}
    </>
  );
};

export default TeamAreaOne;
