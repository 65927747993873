import React from "react";

const AboutAreaSix = () => {
  return (
    <>
      {/* ================== AboutAreaSix start  ==================*/}
      <div className="about-area about-area-margin-bottom pd-top-80">
        <div className="container">
          <div className="box-shadow bg-white z-index-2 border-radius-5 p-xl-5 p-4">
            <div className="row">
              <div
                className="col-lg-6"
                data-aos="fade-left"
                data-aos-delay="200"
                data-aos-duration="1500"
              >
                <div className="section-title mt-5 mt-lg-0 mb-0">
                  <h2 className="title">Building tomorrow's tech echelon's</h2>
                  <p className="content">
                    We are committed to supporting and encouraging outstanding
                    technology entrepreneurs to innovate, grow and bring game
                    changing ideas to the global marketplace. We invest in the
                    most promising founders — those who are automating,
                    decentralizing, democratizing, and expanding human
                    capabilities. We want to be the first to invest in your
                    potential, and to support your leadership. dui.
                  </p>
                  <p className="small-title">
                    We invest in everything that shows tremendous potential —
                    from DevOps to Digital Health to 3D technology — to help
                    founders raise a Series A and beyond.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="about-thumb-inner pe-xl-5 pb-5 me-xl-4"
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-duration="1500"
                >
                  <img
                    className="main-img"
                    src="http://lh3.googleusercontent.com/MuTnIiQlNONsX1Bo_4SxbutWT5X8H5dIdknHGJtgv2M3Xm8JSsi9mTmu6WJcGip3nO2IsTeeVpq0TdJm7PosAIWs=s1101"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================== AboutAreaSix End  ==================*/}
    </>
  );
};

export default AboutAreaSix;
