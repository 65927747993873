import React from "react";
import FooterFour from "../components/FooterFour";
import NavBar from "../components/NavbarTwo";
import TeamDetailsGroup from "../components/TeamDetailsGroup";

const TeamDetailsSingle = () => {
  return (
    <>
      <NavBar />
      <TeamDetailsGroup />
      <FooterFour />
    </>
  );
};

export default TeamDetailsSingle;
