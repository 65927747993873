import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const AboutAreaFive = () => {
  return (
    <>
      {/* =============== About Area Five End ===============*/}
      <div className="about-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 mb-4 mb-lg-0"
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="1500"
            >
              <div className="about-thumb-inner">
                <img
                  className="main-img"
                  src="https://agiletechwp.wowtheme7.com/wp-content/uploads/2023/03/15-1.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-delay="200"
              data-aos-duration="1500"
            >
              <div className="section-title mb-0 ps-xl-5">
                <h6 className="sub-title-sky-blue">EvolveLab</h6>
                <h2 className="title">Pre-seed Investment Fund</h2>
                <p className="content mb-4">
                  We believe in going above and beyond our initial investment.
                  Our team has the experience and the connections to help
                  throughout all phases of your startup journey. We believe in a
                  lasting partnership that sees you through all the critical
                  challenges that early startups encounter.
                </p>
                <p>
                  Our mission is to help founders build momentum as bold and
                  courageous product-led companies. If you’re ready to scale up
                  big and go global, we’re here to increase your odds of
                  success. There's no generic formula for success. And there's
                  no one way of working with us
                </p>
                <Link
                  className="btn btn-base-color border-radius-5"
                  to="/about"
                >
                  Discover More <FaArrowRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =============== About Area Five End ===============*/}
    </>
  );
};

export default AboutAreaFive;
