import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { userData } from "../scripts/data";

const TeamDetailsGroup = () => {
  const [userDetails, setUserDetails] = useState([]);
  const { id } = useParams();

  const findUserById = () => {
    const user = userData.find((user) => user.id === id);
    setUserDetails(user);
  };

  useEffect(() => {
    findUserById();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { img, name, description, position, description_one, description_two } =
    userDetails;

  return (
    <>
      {/* ================= TeamDetailsGroup Start =================*/}
      <div className="team-blog-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="team-details-page">
            <div className="row">
              <div className="col-lg-5 text-center">
                <div className="thumb mb-4 mb-lg-0">
                  <img src={img} alt="img" />
                </div>
              </div>
              <div className="col-lg-7 align-self-center ps-xl-5">
                <h3 className="mb-2">{name}</h3>
                <span className="designation">{position}</span>
                <div className="details mt-4">
                  <h4>About {name}</h4>
                  <p>{description}</p>
                  <p>{description_one}</p>
                  <p>{description_two}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamDetailsGroup;
