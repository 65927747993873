import React from "react";
import FooterOne from "../components/FooterFour";
import NavBar from "../components/NavbarTwo";

const Career = () => {
  return (
    <>
      <NavBar />
      <div className="contact-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="contact-page-inner bg-gray">
            <div className="section-title mb-4 pb-2">
              <h2 className="title">Thank You For Your Interest </h2>
              <p className="content mb-0">
                We have no vacant positions at the moment.
              </p>
            </div>
          </div>
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default Career;
