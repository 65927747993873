import React from "react";

const AboutAreaOne = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className="about-area pd-top-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-thumb-inner pe-xl-5 me-xl-5 "
                data-aos="fade-right"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <img
                  className="animate-img-1 top_image_bounce"
                  src="assets/img/about/2.png"
                  alt="img"
                />
                <img
                  className="animate-img-2 left_image_bounce"
                  src="assets/img/about/3.png"
                  alt="img"
                />
                <img
                  className="animate-img-3 top_image_bounce"
                  src="assets/img/banner/5.svg"
                  alt="img"
                />
                <img
                  className="main-img"
                  src="https://aglietech.wowtheme7.com/assets/img/banner/1.png"
                  alt="img"
                />
              </div>
            </div>
            <div
              className="col-lg-6 "
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="section-title mt-5 mt-lg-0">
                <h6 className="sub-title">ABOUT US</h6>
                <h2 className="title">
                  Your partner in fueling the success of early{" "}
                  <span style={{ color: "#4faf9b" }}>Stage</span> Startups
                </h2>
                <p className="content mb-2 mb-xl-2">
                  We understand that turning innovative ideas into thriving
                  businesses requires more than just funding. That's why we go
                  beyond capital investment to provide comprehensive support and
                  resources tailored specifically to the needs of pre-seed
                  startups.
                </p>
                <p className="content mb-2 mb-xl-2">
                  Our team is comprised of seasoned entrepreneurs, industry
                  veterans, and startup enthusiasts who bring a wealth of
                  knowledge and experience to the table. We understand the
                  unique challenges and opportunities faced by early-stage
                  startups and are dedicated to providing hands-on support and
                  strategic guidance. We act as your fractional CTO, helping you
                  build a solid technology foundation and navigate the
                  complexities of product development.
                </p>
                <p className="content mb-2 mb-xl-2">
                  Join us on this exciting journey of entrepreneurship and let
                  us be your trusted ally. Together, we can overcome obstacles,
                  seize opportunities, and transform your vision into a
                  successful and sustainable business. Discover the
                  possibilities with EvolveLab and take the first step towards
                  unlocking your startup's potential.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutAreaOne;
